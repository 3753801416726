<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/investor/banner-investor.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="position:relative">
                <el-image :src="require('@/assets/investor/bt.png')"></el-image>
                <div style="position: absolute;z-index: 50;right:0px;top: 18%;">
                    <div style="width:450px;height:400px;background: #ffff;padding-left: 80px;padding-top: 80px;padding-bottom: 80px;">
                        <div class="item5">
                            <div class="image5">
                                <el-image :src="require('@/assets/investor/phone.png')"></el-image>
                            </div>
                            <div>
                                <div class="bottom5">联系电话</div>
                                <div class="title5">0371-67988888</div>
                            </div>
                        </div>
                        <div class="item5">
                            <div class="image5">
                                <el-image :src="require('@/assets/investor/email.png')"></el-image>
                            </div>
                            <div>
                                <div class="title5">Securities@smartgen.cn</div>
                                <div class="bottom5">联系邮箱</div>
                            </div>
                        </div>
                        <div class="item5" style="cursor: pointer;" @click="nav('http://irm.cninfo.com.cn/ircs/company/companyDetail?stockcode=301361&orgId=gfbj0430504')">
                            <div class="image5">
                                <el-image :src="require('@/assets/investor/hudong.png')"></el-image>
                            </div>
                            <div >
                                <div class="title5">深交所互动易</div>
                                <div class="bottom5" >点击进入</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px;">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div style="background: #FBFBFB;padding-left: 60px;padding-top: 60px;padding-bottom: 80px;">
                            <el-row>
                                <el-col :span="12">
                                    <div style="text-align: center;font-size: 35px;font-weight: 500;">众智科技</div>
                                    <div style="text-align: center;font-size: 18px;margin-top: 10px;">股票代码：301361</div>
                                </el-col>
                                <el-col :span="12">
                                    <div style="font-size: 48px;font-weight: bold;text-align: center;">
                                        {{jiage}}
                                    </div>
                                    <div style="display: flex;width: 180px;margin: 0 auto;margin-top: 10px;">
                                        <div>
                                            <el-image v-if="fudu<0" :src="require('@/assets/investor/die.png')"></el-image>
                                            <el-image v-if="fudu>0" :src="require('@/assets/investor/zhang.png')"></el-image>
                                        </div>
                                        <div :style="{'color': fudu<0 ? '#02BF98' : '#FF3E00'}" style="margin-left:10px;font-size: 18px;">{{fudu > 0 ? '+'+fudu :fudu}}（{{baifenbi}}%）</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="12" style="cursor: pointer;">
                                <el-image :src="require('@/assets/investor/yeji.png')"  @click="nav('https://datas.p5w.net/norm/301361')"></el-image>
                            </el-col>
                            <el-col :span="12" style="cursor: pointer;" >
                                <el-image :src="require('@/assets/investor/gonggao.png')" @click="nav('https://datas.p5w.net/notice/301361')"></el-image>
                            </el-col>
                            <el-col :span="12" style="margin-top: 15px;cursor: pointer;" >
                                <el-image :src="require('@/assets/investor/huodong.png')" @click="nav('https://ir.p5w.net/c/301361.shtml')"></el-image>
                            </el-col>
                            <el-col :span="12" style="margin-top: 15px;cursor: pointer;" >
                                <el-image :src="require('@/assets/investor/xueyuan.png')" @click="nav('https://datas.p5w.net/notice/301361')"></el-image>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import { investorDate} from "@/api/api"; 
export default {
    data(){
        return{
            queryParams: {
                type: 1,
                page: 1,
                limit: 10,
            },
            total:0,
            list:[],
            daima:'',
            jiage:'',
            fudu:'',
            baifenbi:''
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        this.getList()
    },
    methods:{
        async getList(){
            const{data:res} = await investorDate()
            let arr = res.split('~')
            this.daima = arr[2]
            this.jiage = arr[3]
            this.fudu = +arr[4]
            this.baifenbi = arr[5]
        },
        nav(url){
            window.open(url);
        }
    }
}
</script>
<style scoped>
.appmain{
    width: 70%;
    margin: 0 auto;
    margin-top: 2%;
}
@media screen and (max-width: 480px) {
    .appmain{
        width: 100%;
    }
}

.item5{
    display: flex;
    margin-bottom: 40px;
}
.image5{
    width: 50px;
    height: 50px;
}
.title5{
    font-size: 18px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    margin-left: 20px;
    margin-top: 5px;
}
.bottom5{
    font-size: 15px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(51,51,51,0.6);
    margin-left: 20px;
}
</style>